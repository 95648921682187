<template>
  <div id="teacherManager">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="学生管理" name="first">
        <div>
          <el-button type="primary"
                     style="float:left;margin-top:5px;margin-bottom: 20px;margin-left: 15px"
                     @click="openUserDialog()">新增学员
          </el-button>

          <div style="float:left;margin-top:5px;margin-bottom: 20px;margin-left: 30px">
            <el-form :inline="true" :model="formInline" class="demo-form-inline">
              <el-form-item label="登录账号：">
                <el-input v-model="formInline.loginAccount" placeholder="请输入登录账号"></el-input>
              </el-form-item>
              <el-form-item label="姓名：">
                <el-input v-model="formInline.username" placeholder="请输入姓名"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="selectStudent">查询</el-button>
                <el-button @click="resetForm()">重置</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>

        <el-dialog title="学员信息" :visible.sync="dialogFormVisible" width="600px" :append-to-body="true">
          <el-form :model="user" ref="user">
            <el-form-item label="登录账号：" :label-width="formLabelWidth">
              <el-input v-model="user.loginAccount" autocomplete="off" maxlength="15"
                        placeholder="请输入英文或数字或手机号" clearable></el-input>
            </el-form-item>
            <el-form-item label="姓名：" :label-width="formLabelWidth">
              <el-input v-model="user.username" autocomplete="off" maxlength="10" clearable></el-input>
            </el-form-item>
            <el-form-item label="密码：" :label-width="formLabelWidth">
              <el-input v-model="user.password" autocomplete="off" maxlength="10" clearable></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="addUser()">确 定</el-button>
          </div>
        </el-dialog>

        <el-dialog title="学员信息" :visible.sync="dialogFormVisible3" width="600px" :append-to-body="true">
          <el-form :model="user" ref="user">
            <el-form-item label="登录账号：" :label-width="formLabelWidth">
              <el-input v-model="updateUserBean.loginAccount" autocomplete="off" maxlength="20" disabled></el-input>
            </el-form-item>
            <el-form-item label="姓名：" :label-width="formLabelWidth">
              <el-input v-model="updateUserBean.username" autocomplete="off" maxlength="10" clearable></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible3 = false">取 消</el-button>
            <el-button type="primary" @click="updateUser()">确 定</el-button>
          </div>
        </el-dialog>


        <el-table
            border
            :data="tableData"
            @expand-change="expandChange"
            style="width: 100%"
        >
          <el-table-column
              type="index"
              width="50">
          </el-table-column>
          <el-table-column type="expand">
            <template slot-scope="scope">
              <el-table :data="scope.row.examPaperAnswers"
                        :header-cell-style="{background:'#eef1f6',color:'#606266'}">
                <el-table-column
                    type="index"
                    width="50">
                </el-table-column>
                <el-table-column prop="examPaperId" label="试卷编号" width="80px"></el-table-column>
                <el-table-column prop="paperName" label="试卷标题" width="460px"></el-table-column>
                <!--                                <el-table-column prop="paperType" label="试卷类型"></el-table-column>-->
                <el-table-column prop="questionCount" label="题目数量" width="80px"></el-table-column>
                <el-table-column prop="paperScore" label="试卷总分" width="80px"></el-table-column>
                <el-table-column prop="userScore" label="本次得分" width="80px"></el-table-column>
                <el-table-column prop="doTime" label="答题耗时" width="80px"></el-table-column>
                <el-table-column prop="createTime" label="作答时间" width="170px"></el-table-column>
                <el-table-column

                    label="操作"
                    width="90"
                    >
                  <template slot-scope="scope">
                    <el-button @click="viewPaper(scope.row.id)" type="text" size="small">查看
                    </el-button>
                    <el-button @click="openScoreDialog(scope.row)" type="text" size="small">评分
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <!--                    <el-table-column prop="product_id" label="ID"></el-table-column>-->
          <el-table-column label="账号" prop="loginAccount"></el-table-column>
          <el-table-column label="姓名" prop="username"></el-table-column>
          <el-table-column label="myCode" prop="icode"></el-table-column>
          <el-table-column label="答题次数" sortable prop="answerCount"></el-table-column>
          <el-table-column label="注册时间" prop="createTime"></el-table-column>
          <el-table-column
              fixed="right"
              label="操作"
              width="150">
            <template slot-scope="scope">
              <el-button @click="resetPassword(scope.row.loginAccount)" type="text" size="small">重置密码
              </el-button>
              <el-button @click="openUpdateUserDialog(scope.row)" type="text" size="small">修改
              </el-button>
              <el-button @click="cancelLoginAccount(scope.row.id)" type="text" size="small" style="color: red">注销
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                       :current-page="currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="pageSize"
                       layout="total, sizes, prev, pager, next, jumper" :total="allInfo.total"
                       style="margin-top: 20px;text-align: center">
        </el-pagination>
      </el-tab-pane>

      <el-tab-pane label="试卷分析" name="second">

        <div style="float:left;margin-top:5px;margin-bottom: 20px;margin-left: 30px;">
          <el-form :inline="true" :model="formInline2" class="demo-form-inline">
            <el-form-item label="试卷编号：">
              <el-input v-model="formInline2.id" placeholder="请输入试卷编号" clearable
                        style="width: 150px"></el-input>
            </el-form-item>
            <el-form-item label="试卷标题：">

              <el-input v-model="formInline2.name" placeholder="请输入试卷标题" clearable
                        style="width: 350px"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="getTestPaper">查询</el-button>
            </el-form-item>
          </el-form>
        </div>

        <el-table
            border
            :data="allTestPaperData"
            @expand-change="expandChange"
            :default-sort="{prop: 'id', order: 'descending'}"
            style="width: 100%"
        >
          <el-table-column
              type="index"
              width="50">
          </el-table-column>
          <el-table-column type="expand">
            <template slot-scope="scope">
              <el-table :data="scope.row.examPaperAnswers"
                        :header-cell-style="{background:'#eef1f6',color:'#606266'}">
                <el-table-column
                    type="index"
                    width="50">
                </el-table-column>
                <el-table-column prop="id" label="答题记录" width="100px"></el-table-column>
                <el-table-column prop="userInfo.loginAccount" label="学生账号"></el-table-column>
                <el-table-column prop="userInfo.username" label="学生姓名"></el-table-column>
                <el-table-column prop="userInfo.icode" label="myCode"></el-table-column>
                <el-table-column prop="userScore" label="得分" width="100px"></el-table-column>
                <el-table-column prop="doTime" label="耗时/min"></el-table-column>
                <el-table-column prop="createTime" label="答题时间" width="180px"></el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="120">
                  <template slot-scope="scope">
                    <el-button @click="viewPaper(scope.row.id)" type="text" size="small">查看
                    </el-button>
                    <el-button @click="openScoreDialog(scope.row)" type="text" size="small">人工评分
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <!--                    <el-table-column prop="product_id" label="ID"></el-table-column>-->
          <el-table-column label="试卷编号" prop="id" width="100px"></el-table-column>
          <el-table-column label="试卷名称" prop="name" width="480px"></el-table-column>
          <el-table-column label="试卷分数" prop="score"></el-table-column>
          <el-table-column label="题目总数" prop="questionCount"></el-table-column>
          <el-table-column label="答题次数" sortable prop="answerCount"></el-table-column>
          <!--          <el-table-column label="发布时间" prop="createTime"></el-table-column>-->
          <el-table-column label="操作" prop="createTime">
            <template slot-scope="scope">
              <el-button @click="openStudentWorkDialog(scope.row.id,scope.row.name)" type="text" size="small">布置作业
              </el-button>
            </template>

          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
                       :current-page="currentPage2" :page-sizes="[10, 20, 30, 50]" :page-size="pageSize2"
                       layout="total, sizes, prev, pager, next, jumper" :total="allInfo2.total"
                       style="margin-top: 20px;text-align: center">
        </el-pagination>

      </el-tab-pane>

      <el-dialog title="布置作业-选择学员" :visible.sync="dialogStudentWork" width="500px" :append-to-body="true">

        <div style="margin-bottom: 30px;"><h1>作业： {{ paperName }}</h1></div>
        <el-table
            ref="multipleTable"
            :data="tableData4"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange">
          <el-table-column
              type="selection"
              width="55">
          </el-table-column>
          <el-table-column
              label="账号"
              width="200">
            <template slot-scope="scope">{{ scope.row.loginAccount }}</template>
          </el-table-column>
          <el-table-column
              prop="username"
              label="姓名"
              width="200">
          </el-table-column>

        </el-table>
        <el-pagination @size-change="handleSizeChange4" @current-change="handleCurrentChange4"
                       :current-page="currentPage4" :page-sizes="[10, 20, 30, 50]" :page-size="pageSize4"
                       layout="total, sizes, prev, pager, next, jumper" :total="allInfo4.total"
                       style="margin-top: 20px;text-align: center">
        </el-pagination>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogStudentWork = false">取 消</el-button>
          <el-button type="primary" @click="assignment()">发 布</el-button>
        </div>
      </el-dialog>

      <el-tab-pane label="课堂管理" name="six">
        <div>
          <div style="float:left;margin-top:5px;margin-bottom: 20px;margin-left: 30px">
            <el-form :inline="true" :model="formInline3" class="demo-form-inline">
              <el-form-item label="登录账号：">
                <el-input v-model="formInline3.loginAccount" placeholder="请输入登录账号"></el-input>
              </el-form-item>
              <el-form-item label="姓名：">
                <el-input v-model="formInline3.username" placeholder="请输入姓名"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="selectStudentByClass">查询</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>

        <el-dialog title="课堂记录" :visible.sync="dialogFormVisible2" width="600px">
          <el-form :model="classForm" ref="classForm">
            <el-form-item label="课堂内容：" :label-width="formLabelWidth2">
              <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="请输入内容"
                  v-model="classForm.classContent">
              </el-input>
            </el-form-item>
            <el-form-item label="上课日期：" style="padding-left:20px">
              <!--                            <div class="block">-->
              <el-date-picker style="margin-left: -240px"
                              v-model="classForm.startDate"
                              type="date"
                              placeholder="选择日期">
              </el-date-picker>
              <!--                            </div>-->
            </el-form-item>

            <el-form-item label="上课时间：" :label-width="formLabelWidth2">
              <el-time-select
                  style="margin-left: -15px"
                  placeholder="起始时间"
                  v-model="classForm.startTime"
                  :picker-options="{
                                      start: '09:00',
                                      step: '00:15',
                                      end: '23:30'
                                    }">
              </el-time-select>
              <span>  -  </span>
              <el-time-select
                  placeholder="结束时间"
                  v-model="classForm.endTime"
                  :picker-options="{
                                      start: '09:00',
                                      step: '00:15',
                                      end: '23:30'
                                    }">
              </el-time-select>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible2 = false">取 消</el-button>
            <el-button type="primary" @click="addClass()">确 定</el-button>
          </div>

        </el-dialog>

        <el-table
            border
            :data="tableData3"
            @expand-change="expandChange"
            style="width: 100%"
        >
          <el-table-column
              type="index"
              width="50">
          </el-table-column>
          <el-table-column type="expand">
            <template slot-scope="scope">
              <el-table :data="scope.row.userClassInfos"
                        :header-cell-style="{background:'#eef1f6',color:'#606266'}">
                <el-table-column
                    type="index"
                    width="50">
                </el-table-column>
                <el-table-column prop="id" label="课堂编号"></el-table-column>
                <el-table-column prop="classContent" label="课堂内容" width="500"></el-table-column>
                <el-table-column prop="startDate" label="上课日期" width="200"></el-table-column>
                <el-table-column prop="classTime" label="上课时间" width="200"></el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="100">
                  <template slot-scope="scope">
                    <el-button @click="updateClass(scope.row)" type="text" size="small">修改
                    </el-button>
                    <el-button @click="deleteUserClass(scope.row.id)" type="text" size="small">删除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </template>

          </el-table-column>
          <!--                    <el-table-column prop="product_id" label="ID"></el-table-column>-->
          <el-table-column label="账号" prop="loginAccount"></el-table-column>
          <el-table-column label="姓名" prop="username"></el-table-column>
          <el-table-column label="myCode" prop="icode"></el-table-column>
          <el-table-column label="上课次数" prop="classCount"></el-table-column>
          <el-table-column label="注册时间" prop="createTime"></el-table-column>
          <el-table-column
              fixed="right"
              label="操作"
              width="150">
            <template slot-scope="scope">
              <el-button @click="addClassDialog(scope.row.loginAccount)" type="text" size="small">添加上课记录
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination @size-change="handleSizeChange3" @current-change="handleCurrentChange3"
                       :current-page="currentPage3" :page-sizes="[10, 20, 30, 50]" :page-size="pageSize3"
                       layout="total, sizes, prev, pager, next, jumper" :total="allInfo3.total"
                       style="margin-top: 20px;text-align: center">
        </el-pagination>

      </el-tab-pane>

      <el-tab-pane label="试卷列表" name="fourth">
        <div style="text-align: left;margin-left: 20px;margin-top: 20px;margin-bottom: 200px">
          该功能尚未开放，可将需要录入的试卷/试题发送至邮箱wancode@163.com，会有专员录题，录制完成后将第一时间通知您。
        </div>
      </el-tab-pane>

      <el-tab-pane label="题目列表" name="five">
        <div style="text-align: left;margin-left: 20px;margin-top: 20px;margin-bottom: 200px">
          该功能尚未开放，可将需要录入的试卷/试题发送至邮箱wancode@163.com，会有专员录题，录制完成后将第一时间通知您1。
        </div>
      </el-tab-pane>
    </el-tabs>

    <el-dialog title="更新得分" :visible.sync="dialogScoreVisible" width="400px" :append-to-body="true">
      <el-form :model="scoreInfo" ref="scoreInfo">
        <el-form-item label="人工评分：" :label-width="formLabelWidth">
          <el-input v-model="scoreInfo.score" autocomplete="off" maxlength="10" clearable></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogScoreVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateStudentScore()">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
        title="提示"
        :visible.sync="dialogVisible4"
        width="560px"
        center
        :before-close="handleClose">
      <h3 style="text-align: center;font-size:26px;">{{ content }}</h3>
      <span slot="footer" class="dialog-footer">
                <el-button type="danger" style="text-align: center;font-size:20px;" @click="toSelfVIP()"
                           round>升级 教师版会员</el-button>
            	<div style="padding-bottom: 20px"></div>
                <span style="color: red" v-show="dialogVisible4">注：教学中心只有开通教师版会员的用户方可使用</span>
			</span>
    </el-dialog>
  </div>
</template>

<script>
import {
  editClass,
  agentRegister,
  updateScore,
  updateUser,
  assignment,
  resetPassword,
  getStudentInfo2,
  getPaperListTwo,
  deleteUserClass, cancelLoginAccount,
} from '@/api/index.js'

export default {
  data() {
    return {
      paperId: 0,
      studentIds: [],
      userId: '',
      divShow: false,
      content: '',
      allInfo: {
        total: 0
      },
      allInfo2: {
        total: 0
      },
      allInfo3: {
        total: 0
      },
      allInfo4: {
        total: 0
      },
      currentPage: 1,
      pageSize: 10,

      currentPage2: 1,
      pageSize2: 10,

      currentPage3: 1,
      pageSize3: 10,

      currentPage4: 1,
      pageSize4: 10,

      haveMore: false,
      noMore: false,
      isLoading: true,
      allTestPaperData: [],
      dialogStudentWork: false,
      paperName: '',
      dialogFormVisible: false,
      dialogFormVisible2: false,
      dialogFormVisible3: false,
      dialogScoreVisible: false,
      dialogVisible4: false,
      formLabelWidth: '100px',
      formLabelWidth2: '100px',
      activeName: 'first',
      classForm: {
        id: '',
        loginAccount: '',
        classContent: '',
        startDate: '',
        startTime: '',
        endTime: '',
        classTime: ''
      },
      updateClassForm: {
        id: '',
        loginAccount: '',
        classContent: '',
        startDate: '',
        startTime: '',
        endTime: '',
        classTime: ''
      },
      user: {
        loginAccount: '',
        username: '',
        password: 'password',
      },
      scoreInfo: {
        id: 0,
        score: 0,
      },
      updateUserBean: {
        id: '',
        loginAccount: '',
        username: '',
      },
      formInline: {
        loginAccount: '',
        username: ''
      },
      formInline2: {
        id: null,
        name: ''
      },
      formInline3: {
        loginAccount: '',
        username: ''
      },
      tableData: [{
        loginAccount: '',
        createTime: '',
        username: '',
        answerCount: '',
        iCode: ''
      }],
      tableData2: [
        {
          loginAccount: '',
          createTime: '',
          username: '',
          answerCount: '',
          iCode: ''
        }
      ],
      tableData3: [{
        loginAccount: '',
        createTime: '',
        username: '',
        answerCount: '',
        iCode: ''
      }],
      tableData4: [
        {
          loginAccount: '',
          createTime: '',
          username: '',
          answerCount: '',
          iCode: ''
        }
      ],
      userInfoList: []
    }
  },
  mounted() {
    this.userId = localStorage.getItem("userId")
    if (this.userId === '' || this.userId == null) {
      this.divShow = false
    } else {
      this.divShow = true
      this.selectStudent()
    }
  },
  methods: {
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.userInfoList = val;
    },
    handleClose(done) {
      this.dialogVisible4 = false;
    },
    toSelfVIP() {
      this.dialogVisible = false;
      this.$router.push({path: "/selfVIP"});
    },
    formatter(row, column) {
      return row.address;
    },
    // load(tree, treeNode, resolve) {
    //   setTimeout(() => {
    //     resolve([
    //       {
    //         loginAccount: '',
    //         createTime: '',
    //         username: '',
    //         answerCount: '',
    //         iCode: '',
    //         examPaperAnswers: [{}]
    //       },
    //     ])
    //   }, 1000)
    // },
    getTestPaper(val) {
      let param = {
        type: '',
        level: '',
        name: this.formInline2.name,
        id: this.formInline2.id,
        currentPage: this.currentPage2,
        pageSize: this.pageSize2,
      }
      getPaperListTwo(param).then(res => {
        if (res.code === "99995") {
          this.content = "您未开通【教师版会员】或会员已到期"
          this.dialogVisible4 = true
        } else if (res.code === "0000") {
          this.allInfo2 = res.data
          this.allTestPaperData = res.data.list
        }
      })
    },
    viewPaper(item) {
      let jumpRouter = this.$router.resolve({
        path: "/viewTestPaper?id=" + item
      })
      window.open(jumpRouter.href, '_blank');
    },

    selectStudentByClass() {
      let param = {
        loginAccount: this.formInline3.loginAccount,
        username: this.formInline3.username,
        currentPage: this.currentPage3,
        pageSize: this.pageSize3,
        teacherId: localStorage.getItem("userId")
      }
      getStudentInfo2(param).then(res => {
        if (res.code === "99995") {
          this.content = "您未开通【教师版会员】或会员已到期"
          this.dialogVisible4 = true
        } else if (res.code === "0000") {
          this.allInfo3 = res.data
          this.tableData3 = res.data.list
        }
      })
    },
    selectStudent() {
      let param = {
        loginAccount: this.formInline.loginAccount,
        username: this.formInline.username,
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        teacherId: localStorage.getItem("userId")
      }
      getStudentInfo2(param).then(res => {
        if (res.code === "99995") {
          this.content = "您未开通【教师版会员】或会员已到期"
          this.dialogVisible4 = true
        } else if (res.code === "0000") {
          this.allInfo = res.data
          this.tableData = res.data.list
          // this.load()
        }
      })
    },
    resetForm() {
      this.formInline.loginAccount = '',
          this.formInline.username = ''
    },
    handleClick(tab, event) {
      if (tab.index == 1) {
        this.getTestPaper()
      }
      if (tab.index == 2) {
        this.selectStudentByClass()
      }


    },
    deleteUserClass(id) {
      this.$confirm('此操作是删除课堂记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {
          id: id
        }
        deleteUserClass(param).then(res => {
          if (res.code === "0000") {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }
          this.selectStudentByClass()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '删除失败，可联系客服!'
          });
        });

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    },
    resetPassword(value) {
      this.$confirm('此操作是将密码重置为“password”, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {
          loginAccount: value
        }
        resetPassword(param).then(res => {
          if (res.code === "0000") {
            this.$message({
              type: 'success',
              message: '重置成功!'
            });
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '重置失败，可联系客服修改密码!'
          });
        });

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消重置'
        });
      });
    },

    cancelLoginAccount(id) {
      this.$prompt('请输入您的登录密码', '此操作是将账号注销, 是否继续?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({value}) => {
        let param = {
          password: value,
          id: id,
          belongTo: parseInt(localStorage.getItem("userId")),
        }
        console.log(param)
        cancelLoginAccount(param).then(res => {
          if (res.code === "0000") {
            this.$message({
              type: 'success',
              message: '注销成功!'
            });
            this.selectStudent()
          } else if (res.code === "99995") {
            this.$message({
              type: 'info',
              message: res.message
            });
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '注销失败，可联系客服后台注销!'
          });
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消'
        });
      });
      this.selectStudent()
    },
    expandChange(tab, event) {

    },
    openStudentWorkDialog(paperId, paperName) {
      this.paperName = paperName
      this.paperId = paperId
      this.dialogStudentWork = true

      let param = {
        loginAccount: this.formInline3.loginAccount,
        username: this.formInline3.username,
        currentPage: this.currentPage3,
        pageSize: this.pageSize3,
        teacherId: localStorage.getItem("userId")
      }
      getStudentInfo2(param).then(res => {
        if (res.code === "99995") {
          this.content = "您未开通【教师版会员】或会员已到期"
          this.dialogVisible4 = true
        } else if (res.code === "0000") {
          this.allInfo4 = res.data
          this.tableData4 = res.data.list
        }
      })
    },
    openUserDialog() {
      this.dialogFormVisible = true
    },
    openScoreDialog(row) {
      this.dialogScoreVisible = true
      this.scoreInfo.id = row.id
      this.scoreInfo.score = row.userScore
    },
    openUpdateUserDialog(row) {
      this.dialogFormVisible3 = true
      this.updateUserBean.loginAccount = row.loginAccount
      this.updateUserBean.username = row.username
      this.updateUserBean.id = row.id
      // console.log(row)
    },
    updateClass(value) {
      this.dialogFormVisible2 = true
      this.classForm.id = value.id,
          this.classForm.loginAccount = value.loginAccount,
          this.classForm.classContent = value.classContent,
          this.classForm.startDate = value.startDate
      this.classForm.startTime = value.classTime.split('-')[0]
      this.classForm.endTime = value.classTime.split('-')[1]
    },
    addClassDialog(value) {
      this.dialogFormVisible2 = true
      this.classForm.classContent = ''
      this.classForm.startDate = ''
      this.classForm.startTime = ''
      this.classForm.endTime = ''
      this.classForm.loginAccount = value
      this.classForm.id = ''
    },
    assignment() {
      let param = {
        paperId: this.paperId,
        userInfoList: this.userInfoList,
        teacherId: localStorage.getItem("userId")
      }
      assignment(param).then(res => {
            if (res.code === "0000") {
              this.loading = false
              this.dialogStudentWork = false
              this.$message.success("发布成功")
            } else {
              this.$message({
                message: res.message,
                type: 'warning'
              });
            }
          }
      )
    },
    updateUser() {
      if (this.updateUserBean.loginAccount === '') {
        this.$message.error("请输入登录账号")
        return
      }
      if (this.updateUserBean.username === '') {
        this.$message.error("请输入姓名")
        return
      }
      this.$refs.user.validate(valid => {
        if (valid) {
          let param = {
            loginAccount: this.updateUserBean.loginAccount,
            username: this.updateUserBean.username,
            id: this.updateUserBean.id
          }
          updateUser(param).then(res => {
            if (res.code === "0000") {
              this.loading = false
              this.dialogFormVisible3 = false
              this.$message.success("修改成功")
              this.updateUserBean.loginAccount = ''
              this.updateUserBean.username = ''
              this.updateUserBean.id = ''
            }
            this.loading = false
            this.dialogFormVisible3 = false
            this.selectStudent()
          });
        }
      });
      this.dialogFormVisible = false
    },
    updateStudentScore() {
      let param = {
        id: this.scoreInfo.id,
        score: this.scoreInfo.score
      }
      updateScore(param).then(res => {
        if (res.code === "0000") {
          this.scoreInfo.id = null
          this.scoreInfo.score = 0
          this.selectStudent()
          this.getTestPaper()
          this.$message.success("更新得分")
        }
        this.dialogScoreVisible = false
      }).catch(() => {
        this.dialogScoreVisible = false
      });
    },
    addUser() {
      if (this.user.loginAccount === '') {
        this.$message.error("请输入登录账号")
        return
      }
      if (this.user.username === '') {
        this.$message.error("请输入姓名")
        return
      }
      if (this.user.password === '') {
        this.$message.error("请输入密码")
        return
      }
      this.$refs.user.validate(valid => {
            if (valid) {
              let param = {
                loginAccount: this.user.loginAccount,
                username: this.user.username,
                password: this.user.password,
                teacherId: localStorage.getItem("userId")
              }
              agentRegister(param).then(res => {
                    if (res.code === "99995") {
                      this.content = "您未开通【教师版会员】或会员已到期"
                      this.dialogVisible4 = true
                    } else if (res.code === "0000") {
                      this.loading = false
                      this.dialogFormVisible = false
                      this.$message.success("注册成功")
                      this.user.loginAccount = ''
                      this.user.username = ''
                      this.loading = false
                      this.dialogFormVisible = false
                      this.selectStudent()
                    } else {
                      this.$message({
                        message: res.message,
                        type: 'warning'
                      });
                    }
                  }
              )
              ;
            }
          }
      )
      ;
      this.dialogFormVisible = false
    },
    addClass() {
      if (this.classForm.classContent === '') {
        this.$message.error("请输入课堂内容")
        return
      }
      if (this.classForm.startDate === '') {
        this.$message.error("请输入上课日期")
        return
      }
      if (this.classForm.startTime === '') {
        this.$message.error("请输入上课开始时间")
        return
      }
      if (this.classForm.endTime === '') {
        this.$message.error("请输入上课结束时间")
        return
      }
      this.$refs.classForm.validate(valid => {
        if (valid) {
          let param = {
            startTime: this.classForm.startTime,
            endTime: this.classForm.endTime,
            classContent: this.classForm.classContent,
            startDate: this.classForm.startDate,
            loginAccount: this.classForm.loginAccount,
            id: this.classForm.id,
            classState: 3,
          }
          editClass(param).then(res => {
            if (res.code === "0000") {
              this.loading = false
              this.dialogFormVisible2 = false
              this.$message.success("记录成功")
            }
            this.loading = false
            this.dialogFormVisible2 = false
            this.selectStudentByClass()
          }).catch(() => {
            this.loading = false;
            this.dialogFormVisible2 = false
          });
        }
      }).catch(() => {
        this.loading = false;
      });
      this.dialogFormVisible2 = false

    }
    ,
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.selectStudent()
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.selectStudent()
    },

    handleSizeChange2(val) {
      this.pageSize2 = val;
      this.currentPage2 = 1;
      this.getTestPaper()
    },
    handleCurrentChange2(val) {
      this.currentPage2 = val;
      this.getTestPaper()
    },


    handleSizeChange3(val) {
      this.pageSize3 = val;
      this.currentPage3 = 1;
      this.selectStudentByClass()
    },
    handleCurrentChange3(val) {
      this.currentPage3 = val;
      this.selectStudentByClass()
    },
    handleSizeChange4(val) {
      this.pageSize4 = val;
      let param = {
        loginAccount: this.formInline3.loginAccount,
        username: this.formInline3.username,
        currentPage: this.currentPage4,
        pageSize: this.pageSize4,
        teacherId: localStorage.getItem("userId")
      }
      getStudentInfo2(param).then(res => {
        if (res.code === "99995") {
          this.content = "您未开通【教师版会员】或会员已到期"
          this.dialogVisible4 = true
        } else if (res.code === "0000") {
          this.allInfo4 = res.data
          this.tableData4 = res.data.list
        }
      })
    },
    handleCurrentChange4(val) {
      this.currentPage4 = val;
      let param = {
        loginAccount: this.formInline3.loginAccount,
        username: this.formInline3.username,
        currentPage: this.currentPage4,
        pageSize: this.pageSize4,
        teacherId: localStorage.getItem("userId")
      }
      getStudentInfo2(param).then(res => {
        if (res.code === "99995") {
          this.content = "您未开通【教师版会员】或会员已到期"
          this.dialogVisible4 = true
        } else if (res.code === "0000") {
          this.allInfo4 = res.data
          this.tableData4 = res.data.list
        }
      })
    },
  }
}
</script>

<style>
#teacherManager {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  min-height: 62.5vh;
  background: #ffffff;
}

/* 解决element-ui的table表格控件表头与内容列不对齐问题 */
body .el-table th.gutter {
  display: table-cell !important;
}
</style>
